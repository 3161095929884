html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}
.blur-content {
  filter: blur(5px); /* Adjust the blur radius as needed */
}
